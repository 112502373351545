import { FC, useMemo } from "react";
import { AdaptiveCard } from "adaptivecards-react";
import ChatMessageMarkdown from "./chat-message-markdown";

const hostConfig = {
  fontFamily: "-apple-system, BlinkMacSystemFont, Segoe UI, Roboto, Helvetica, Arial, sans-serif, Apple Color Emoji, Segoe UI Emoji",
  fontSizes: {
    default: 16,
    small: 14,
    medium: 18,
    large: 20,
    extraLarge: 22,
  }
};

const ChatMessageAdaptiveCard: FC<{
  text: string;
  onSendMessage?: (message: string, signal: AbortSignal) => void,
}> = ({ text, onSendMessage }) => {
  
  const payload = useMemo(() => JSON.parse(text), [text]);

  const onExecuteAction = (action: any) => {
    if (action && onSendMessage) {
      const sendValue = JSON.stringify({ action: "call_skill", data: action.data });
      console.log(sendValue);
      onSendMessage(`>${sendValue}`, AbortSignal.timeout(300000));
    }
  }

  try {
    return <AdaptiveCard payload={payload} hostConfig={hostConfig} onExecuteAction={onExecuteAction} />
  }
  catch {
    return <ChatMessageMarkdown text={text} />
  }
}

export default ChatMessageAdaptiveCard;