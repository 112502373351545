import { State, useHookstate } from "@hookstate/core";
import { CopyButton, Group, Stack, Card, Tooltip, ActionIcon, Text, useMantineColorScheme, Popover, Button, Divider, ScrollArea, Table, Anchor, Avatar, Flex, useMantineTheme } from "@mantine/core";
import { IconCheck, IconCopy, IconFileInfo, IconMailFast } from "@tabler/icons-react";
import moment from "moment";
import { FC, useMemo } from "react";
import { useTranslation } from "react-i18next";
import { formatBytes, truncateText } from "src/core/utils/object";
import { ChatRequest, ChatResponse } from "src/stores/chat";
import { container } from 'src/inversify.config';
import { AppConfiguration, useAuthentication } from "../../core/services/authentication-service";
import { ChatFileInfo } from "./chat-send-message";
import { DocumentReferenceItem } from "src/stores/skills";
import gravatarUrl from 'gravatar-url';
import ChatMessageMarkdown from "./chat-message-markdown";

function textIsAction(text: string) {
  if (text && text.startsWith(">")) {
    const rawJson = text.substring(1);
    try {
      JSON.parse(rawJson);
      return true;
    } catch {
      return false;
    }
  }
  return false;
}

const ChatMessageUser: FC<{
  state: State<ChatRequest | ChatResponse>;
  onSendMessage?: (message: string, signal: AbortSignal) => void,
  onMessageClick?: () => void;
  isBusy?: boolean;
}> = ({ state, onSendMessage, onMessageClick, isBusy }) => {
  const scopedState = useHookstate(state);
  const { t } = useTranslation();
  const theme = useMantineTheme();
  const { colorScheme } = useMantineColorScheme();
  const [authStatus] = useAuthentication();
  const baseUri = container.get<AppConfiguration>("AppConfiguration")?.serviceUrl || `${window.location.protocol}//${window.location.host}`;

  (window as any).ask = (text: string) => {
    if (onSendMessage) {
      onSendMessage(text, AbortSignal.timeout(300000));
    }
  }

  const displayName = authStatus?.user.value?.name ?? authStatus?.user.value?.email ?? scopedState?.identity?.value ?? authStatus?.user.value?.preferred_username ?? authStatus?.user.value?.sub
  const email = authStatus?.user.value?.email ?? authStatus?.user.value?.preferred_username ?? authStatus?.user.value?.sub
  const gravatar = useMemo(() => {
    return gravatarUrl(email, { size: 80, default: 'mm' })
  }, [email])

  const getProperties = (key: string, properties: any): [] => {
    if (!properties || !properties[key]) return [];
    return properties[key] instanceof Array ? properties[key] : [properties[key]];
  }

  const importDocuments = getProperties('importDocuments', scopedState.properties?.value);
  const selectedDocuments = getProperties('selectedDocuments', scopedState.properties?.value);

  const text = (scopedState as State<ChatRequest>).text.value;
  if (textIsAction(text)) {
    return <Group justify="flex-start">
      <Flex gap={"xs"} justify="flex-start" align="flex-start" direction="row" p={5} ml={10}>
        <IconMailFast color={theme.colors[theme.primaryColor][6]} size={16} />
        <Text span size="sm">{t('User selection sent')}</Text>
      </Flex>
    </Group>
  }

  const showAttachedFiles = (items: ChatFileInfo[]) => {
    return (
      <>
        {items.length > 1 &&
          <Popover width={400} position="bottom-end" withArrow shadow="xl">
            <Popover.Target>
              <Tooltip label={t("Show attached files")}>
                <Button variant="light" leftSection={<IconFileInfo size={16} />} radius="xl" size="xs">{items.length} {t("Attached files")}</Button>
              </Tooltip>
            </Popover.Target>
            <Popover.Dropdown>
              <Stack gap="xs">
                <Group justify="space-between">
                  <Text>{t("Attached files")}</Text>
                </Group>
                <Divider />

                <ScrollArea.Autosize mah={250}>
                  <Table striped highlightOnHover withRowBorders={false}>
                    <Table.Tbody>
                      {items.map((item: ChatFileInfo, index: number) => (
                        <Table.Tr key={item.title}>
                          <Table.Td px={0}>
                            {item.id ?
                              <Anchor target="_blank" href={`${baseUri}/viewer/${item.id}`}>{truncateText(item.title, 40)}</Anchor>
                              :
                              truncateText(item.title, 40)
                            }
                          </Table.Td>
                          {item.size &&
                            <Table.Td px={0} style={{ width: 100 }}>
                              {formatBytes(item.size)}
                            </Table.Td>
                          }
                        </Table.Tr>
                      ))}
                    </Table.Tbody>
                  </Table>
                </ScrollArea.Autosize>
              </Stack>
            </Popover.Dropdown>
          </Popover>
        }
        {items.length === 1 &&
          <Tooltip withinPortal label={items[0].title}>
            {items[0].id ?
              <Button variant="light" component="a" target="_blank" href={`${baseUri}/viewer/${items[0].id}`}
                leftSection={<IconFileInfo size={16} />} radius="xl" size="xs">{truncateText(items[0].title ?? "", 24)}</Button>
              :
              <Button variant="light" leftSection={<IconFileInfo size={16} />} radius="xl" size="xs">{truncateText(items[0].title ?? "", 24)}</Button>
            }
          </Tooltip>
        }
      </>
    );
  }

  return (
    <CopyButton value={text}>
      {({ copied, copy }) => (
        <Stack gap={30}>
          <Card radius="md" padding={5} onClick={() => onMessageClick?.()}>
            <Flex gap="xs" justify="flex-start" align="flex-start" direction="row">
              <Avatar size="sm" src={gravatar} title={`${displayName} (${email}) - ${moment(scopedState.timestamp.value).fromNow()}`} />

              <Stack gap={5} align="flex-start" justify="flex-start">
                <Text fw={500}>{displayName}</Text>

                <div data-color-mode={colorScheme}>                  
                  <ChatMessageMarkdown text={text} />

                  {((importDocuments && importDocuments.length > 0) || (selectedDocuments && selectedDocuments.length > 0)) &&
                    <Card.Section inheritPadding py={5}>
                      {showAttachedFiles(importDocuments.map((importDocument: any) => ({ title: importDocument.fileName, size: importDocument.fileSize } as ChatFileInfo)))}
                      {showAttachedFiles(selectedDocuments.map((importDocument: any) => ({ title: importDocument.documentTitle, id: importDocument.documentId } as ChatFileInfo)))}
                    </Card.Section>
                  }

                  {scopedState.media?.value &&
                    <Card.Section inheritPadding py={5}>
                      {showAttachedFiles(scopedState.media.value.map((media: DocumentReferenceItem) => ({ title: media.title, id: media.documentOrSnapshotId } as ChatFileInfo)))}
                    </Card.Section>
                  }
                </div>

                <Group gap="xs">
                  {!isBusy &&
                    <Tooltip label={copied ? t('Copied') : t('Copy')} withArrow>
                      <ActionIcon variant='subtle' size={20} color={copied ? 'teal' : 'gray'} onClick={copy}>
                        {copied ? <IconCheck size="1rem" /> : <IconCopy size="1rem" />}
                      </ActionIcon>
                    </Tooltip>
                  }
                </Group>
              </Stack>
            </Flex>
          </Card>
        </Stack>
      )}
    </CopyButton>
  );
}

export default ChatMessageUser;