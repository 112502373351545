import DataStore, { Query, QueryResult } from "src/core/stores/data-store";
import FormStore from "src/core/stores/form-store";
import { ISnapshotSelectStore, SkillBasicInfo, SnapshotBasic, TagItem } from "./skills";
import { AppConfiguration } from "src/core/services/authentication-service";
import { container } from "src/inversify.config";
import { AccessMode, MemberItem } from "./identities";
import { DocumentSummary } from "./documents";
import { MIME_TYPES } from "@mantine/dropzone";

export type BotStrategy = 'Router' | 'Sequential' | 'Llm';
export type BotStatus = 'Draft' | 'Published' | 'Retired';
export type BotTrainingStatus = 'Pending' | 'Training' | 'Trained' | 'Error';
export type BotMemberPrivileges = 'User' | 'Reader' | 'Owner' | 'Contributor' | 'ImpersonatedUser' | 'Tester';
export type BotRateLimit = 'None' | 'Session' | 'User';
export type BotLlmOrchestratorType = 'None' | 'Functions' | 'Simple' | 'ReAct';
export const allowedMimeTypes = [MIME_TYPES.pdf, MIME_TYPES.docx, MIME_TYPES.doc, MIME_TYPES.xlsx, MIME_TYPES.xls, MIME_TYPES.pptx, MIME_TYPES.ppt, MIME_TYPES.csv, MIME_TYPES.mp4, MIME_TYPES.avif, MIME_TYPES.heic, "audio/mpeg", "audio/vnd.wav", "", "text/plain", "application/vnd.oasis.opendocument.text", "image/png", "image/gif", "image/jpeg", "image/webp"];

export interface BotSummary {
  id: string;
  enable: boolean;
  title: string;
  description: { [key: string]: string };
  accessMode: AccessMode;
  strategy: BotStrategy;
  status: BotStatus;
  jobReference: string;
  trainingStatus: BotTrainingStatus;
  currentSnapshotDateTime?: Date;
  tags: { [key: string]: any };
}

export interface BotBasicInfoItem {
  id: string;
  enable: boolean;
  title: string;
  description: { [key: string]: string };
  alert: { [key: string]: string };
  strategy: BotStrategy;
  userPreferences: string;
  skills: SkillBasicInfo[];
  enableFeedBackGathering: boolean;
  logo: string;
  enableDocumentManagement: boolean;
  enablePersonalCollection: boolean;
  enableSaveConversation: boolean;
}

export interface BotItem {
  id: string;
  enable: boolean;
  title: string;
  description: { [key: string]: string };
  jobReference: string;
  trainingStatus: BotTrainingStatus;
  accessMode: AccessMode;
  members: MemberItem[];
  strategy: BotStrategy;
  currentSnapshot: BotSnapshotItem;
  tags: { [key: string]: any };
  apiUrl: string;
  logApiKeys: { [key: string]: LogApiKeyItem };
  properties: { [key: string]: any };
}

export interface LogApiKeyItem {
  audiences: string[];
  anonimize: boolean;
}

export interface BotSnapshotItem {
  id: string;
  botId: string;
  content: string;
  hash: string;
  status: BotStatus;
  trainingStatus: BotTrainingStatus;
  variables: { [key: string]: any };
  createdOn: Date;
  createdBy: string;
}

export interface NewBotForm {
  title: string;
  description: { [key: string]: string };
  accessMode: AccessMode;
  members: MemberItem[];
  strategy: BotStrategy;
  tags: TagItem[];
}

export interface UpsertBotSnapshotItem {
  id: string;
  enable: boolean;
  title: string;
  description: { [key: string]: string };
  strategy: BotStrategy;
  accessMode: AccessMode;
  members: MemberItem[];
  tags: { [key: string]: any };
  content: string;
  variables: { [key: string]: any };
  logApiKeys: { [key: string]: LogApiKeyItem };
  properties: { [key: string]: any };
}

export interface BotSnapshotSummary {
  id: string;
  botId: string;
  hash: string;
  status: BotStatus;
  trainingStatus: BotTrainingStatus;
  createdOn: Date;
  createdBy: string;
  isCurrent: boolean;
}

export interface BotSnapshotCompareResult {
  botId: string;
  original: BotSnapshotCompareItem;
  modified: BotSnapshotCompareItem;
}

export interface BotSnapshotCompareItem {
  id: string;
  content: string;
  createdOn: Date;
  createdBy: string;
}

export interface BotSearchOptions {
  enable: boolean;
  cutOff: number;
  confidence: number;
  maxResults: number;
  maxPassageLength: number;
}

export interface BotSearchMrc {
  enable: boolean;
  confidence: number;
  algorithm: string;
  similarity: number;
  maxPassageLength: number;
}

export interface BotContentMicrosoftIntegrations {
  enable: boolean;
  stripUsernameDomain: boolean;
  appType?: string;
  appId?: string;
  appPassword?: string;
  tenantId?: string;
  oAuthProfile?: string;
}

export interface BotContentInsightsIntegrations {
  enable: boolean;
  trackUsername: boolean;
  anonymize: boolean;
  service?: string;
}

export interface BotContentIntegrations {
  insights: BotContentInsightsIntegrations;
  microsoft: BotContentMicrosoftIntegrations;
}

export interface BotContent {
  options: BotContentOptions;
  disambiguation: BotContentDisambiguation;
  mrc: BotSearchMrc;
  search: BotSearchOptions;
  skills: BotContentSkill[];
  llm: BotContentLlm;
  integrations: BotContentIntegrations;
  limits: BotContentRateLimit;
  userPreferences: BotContentUserPreferences;
  personalization: BotContentPersonalization;
}

export interface BotContentOptions {
  enableFeedbackGathering: boolean;
  enableSentimentAnalysis: boolean;
  enableEvents: boolean;
  toxicClassification: boolean;
  greetingsSkill?: string;
  fallbackSkill?: string;
  confidence: number;
  maxSuggestions: number;
  enableDocumentManagement: boolean;
  alert: { [key: string]: string };
}

export interface BotContentDisambiguation {
  enable: boolean;
  confidence: number;
  similarity: number;
  maxSuggestions: number;
  message: { [key: string]: BotContentDisambiguationMessage };
}

export interface BotContentDisambiguationMessage {
  body: string;
}

export interface BotContentSkill {
  skillId: string;
  audiences: string[];
  confidence: number;
  snapshotId?: string;
}

export interface BotContentLlm {
  behavior: { [key: string]: string };
  service: string;
  maxFunctions: number;
  temperature: number;
  maxTokensResponse: number;
  messageExpiration: number;
  stream: boolean;
  enableSaveSnippet: boolean;
  orchestratorType: BotLlmOrchestratorType;
}

export interface BotContentRateLimit {
  rateLimit: BotRateLimit;
}

export interface BotContentUserPreferences {
  temperature: number;
  allowUserChangeTemperature: boolean;
  searchSkills: string[];
  allowUserChangeSearchSkills: boolean;
  searchLanguages: string[];
  allowUserChangeSearchLanguages: boolean;
  allowInternetAccess: boolean;
  allowUserChangeInternetAccess: boolean;
  enableOCRInDocuments: boolean;
  allowEnableOCRInDocuments: boolean;
}

export interface UserPreferences {
  temperature: number;
  searchSkills: string[];
  searchLanguages: string[];
  allowInternetAccess: boolean;
  enableOCRInDocuments: boolean;
  hash: string;
}

export interface FeedbackMessageItem {
  action: 'Like' | 'Dislike';
  comments?: string;
}

export interface BotContentPersonalization {
  logo?: string;
}

export const GetBotDefaultContent = (strategy: BotStrategy, t: any, lang?: string) => {
  const allowedLanguages = container.get<AppConfiguration>("AppConfiguration").allowedLanguages;
  const defaultBehaviour: { [key: string]: string } = {};
  const defaultGuards: { [key: string]: string } = {};
  const defaultDisambiguationMessage: { [key: string]: BotContentDisambiguationMessage } = {};
  const defaultLang = lang ?? allowedLanguages[0];
  defaultBehaviour[defaultLang] = t("You are a very useful and intelligent assistant. If possible, provide URL sources as citations.", { lng: defaultLang });
  defaultGuards[defaultLang] = t("Always answer with love, respect and truth. Respond confidently, positively and neutrally. Avoid harmful, unethical, malicious, racist, sexist, illegal, prejudiced, toxic or negative content. Never respond to anything that causes harm or destruction.", { lng: defaultLang });
  defaultDisambiguationMessage[defaultLang] = { body: t("I think some of these suggestions may be useful to you", { lng: defaultLang }) };

  return {
    options: {
      enableFeedbackGathering: false,
      enableSentimentAnalysis: false,
      enableEvents: true,
      toxicClassification: false,
      confidence: 0.55,
      maxSuggestions: 3,
      enableDocumentManagement: false,
      alert: {
        "en": ""
      }
    },
    search: {
      enable: true,
      cutOff: 0.85,
      confidence: 0.65,
      enableInternetSearch: false,
      minScore: 0.8,
      maxResults: 10,
      maxPassageLength: 0
    },
    mrc: {
      enable: strategy !== 'Llm',
      confidence: 0.1,
      algorithm: 'naive',
      similarity: 0.02,
      maxPassageLength: 1024,
      temperature: 0.5
    },
    disambiguation: {
      enable: strategy !== 'Llm',
      confidence: 0.6,
      similarity: 0.02,
      maxSuggestions: 3,
      message: defaultDisambiguationMessage
    },
    skills: [],
    llm: {
      behavior: defaultBehaviour,
      maxFunctions: 6,
      service: '',
      maxTokensResponse: 500,
      messageExpiration: 500,
      temperature: 0.5,
      enableSaveSnippet: false,
      orchestratorType: 'Functions',
      stream: true
    },
    integrations: {
      insights: {
        anonymize: false,
        enable: false,
        trackUsername: false
      },
      microsoft: {
        enable: false,
        stripUsernameDomain: false
      }
    },
    limits: {
      rateLimit: "None"
    },
    userPreferences: {
      temperature: 0.5,
      allowUserChangeTemperature: false,
      searchSkills: [],
      allowUserChangeSearchSkills: false,
      searchLanguages: [],
      allowUserChangeSearchLanguages: false,
      allowInternetAccess: false,
      allowUserChangeInternetAccess: false,
      enableOCRInDocuments: false,
      allowEnableOCRInDocuments: false
    },
    personalization: {
      logo: ''
    }
  } as BotContent;
}

export class BotSummaryStore extends DataStore<BotSummary> {
  constructor(baseUrl: string) {
    super(`${baseUrl}/api/bots`, []);
  }
}

export class BotBasicInfoItemStore extends FormStore<BotBasicInfoItem, BotBasicInfoItem> {
  constructor(baseUrl: string) {
    super(`${baseUrl}/api/bots`);
  }

  public async getInfo(botId: string) {
    return await this.handleCallAsync(async () => {
      const response = await this.httpService.get<BotBasicInfoItem>(`${this.baseUrl}/${botId}/info`);
      this._state.set((s) => {
        s.item = response.data;
        return s;
      });
      return response.data;
    });
  }

  public async sendFeedback(botId: string, conversationId: string, messageId: string, feedback: FeedbackMessageItem) {
    await this.handleCallAsync(async () => {
      await this.httpService.post(`${this.baseUrl}/${encodeURIComponent(botId)}/conversation/${encodeURIComponent(conversationId)}/message/${encodeURIComponent(messageId)}`, feedback);
    });
  }
}

export class BotItemStore extends FormStore<BotItem, BotItem> {
  constructor(baseUrl: string) {
    super(`${baseUrl}/api/bots`);
  }

  public async saveSnapshot(id: string, item: UpsertBotSnapshotItem) {
    return await this.handleCallAsync(async () => {
      const result = await this.httpService.put<UpsertBotSnapshotItem, BotItem>(`${this.baseUrl}/${encodeURIComponent(id)}`, item);
      this._state.set((s) => {
        s.item = result.data;
        return s;
      });
      return result.data;
    });
  }

  public async publish(id: string) {
    await this.handleCallAsync(async () => {
      await this.httpService.post(`${this.baseUrl}/${encodeURIComponent(id)}/publish`, null);
    });
  }

  public async retire(id: string) {
    await this.handleCallAsync(async () => {
      await this.httpService.post(`${this.baseUrl}/${encodeURIComponent(id)}/retire`, null);
    });
  }

  public async trainSnapshot(id: string, snapshotId: string) {
    await this.handleCallAsync(async () => {
      await this.httpService.post<any, any>(`${this.baseUrl}/${encodeURIComponent(id)}/snapshots/${snapshotId}/train`, {});
    });
  }
}

export class BotSnapshotHistoryStore extends DataStore<BotSnapshotSummary> {
  private _serviceUri: string;

  constructor(baseUrl: string) {
    super(`${baseUrl}/api/bots`, []);
    this._serviceUri = `${baseUrl}`;
  }

  public setBotId(botId: string) {
    this.baseUrl = `${this._serviceUri}/api/bots/${botId}/history`;
  }

  public async deleteSnapshot(botId: string, snapshotId: string) {
    await this.handleCallAsync(async () => {
      await this.httpService.delete<any, any>(`${this._serviceUri}/api/bots/${botId}/snapshots/${snapshotId}`);
    });
  }

  public async trainSnapshot(botId: string, snapshotId: string) {
    await this.handleCallAsync(async () => {
      await this.httpService.post<any, any>(`${this._serviceUri}/api/bots/${botId}/snapshots/${snapshotId}/train`, {});
    });
  }

  public async publishSnapshot(botId: string, snapshotId: string) {
    await this.handleCallAsync(async () => {
      await this.httpService.post<any, any>(`${this._serviceUri}/api/bots/${botId}/snapshots/${snapshotId}/publish`, {});
    });
  }

  public async retireSnapshot(botId: string, snapshotId: string) {
    await this.handleCallAsync(async () => {
      await this.httpService.post<any, any>(`${this._serviceUri}/api/bots/${botId}/snapshots/${snapshotId}/retire`, {});
    });
  }

  public async createNewVersionFromSnapshot(botId: string, snapshotId: string) {
    await this.handleCallAsync(async () => {
      await this.httpService.post<any, any>(`${this._serviceUri}/api/bots/${botId}/snapshots/${snapshotId}/createversion`, {});
    });
  }
}

export class BotSnapshotCompareStore extends FormStore<BotSnapshotCompareResult, BotSnapshotCompareResult> {
  constructor(baseUrl: string) {
    super(`${baseUrl}/api/bots`);
  }

  public async compare(botId: string, snapshotId: string, compareWithPrevious: boolean = false) {
    await this.handleCallAsync(async () => {
      const response = await this.httpService.get<BotSnapshotCompareResult>(`${this.baseUrl}/${botId}/snapshots/${snapshotId}/compare?compareWithPrevious=${compareWithPrevious}`);
      this._state.set((s) => {
        s.item = response.data;
        return s;
      });
    });
  }
}

export class BotSnapshotSelectStore extends DataStore<SnapshotBasic> implements ISnapshotSelectStore {
  private _serviceUri: string;

  constructor(baseUrl: string) {
    super(`${baseUrl}/api/bots`, []);
    this._serviceUri = `${baseUrl}`;
  }

  public setBotId(botId: string) {
    this.baseUrl = `${this._serviceUri}/api/bots/${botId}/history`;
  }

  public async get(query: Partial<Query>) {
    await this.handleCallAsync(async () => {
      const response = await this.httpService.get<QueryResult<SnapshotBasic>>(`${this.baseUrl}?${DataStore.buildUrl(query as Query)}`);
      this._state.set((s) => {
        s.items = response.data.items || (response.data as any).value || [];
        s.count = response.data.count || (response.data as any)['@odata.count'] || 0;
        return s;
      });
    });
  }
}


export class BotDocumentSummaryStore extends DataStore<DocumentSummary> {
  private _serviceUri: string;

  constructor(baseUrl: string) {
    super(`${baseUrl}/api/bots`, []);
    this._serviceUri = `${baseUrl}`;
  }

  public setBotAndCollection(botId: string, collectionId: string) {
    this.baseUrl = `${this._serviceUri}/api/bots/${botId}/collections/${collectionId}/documents`;
  }
}