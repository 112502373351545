import DataStore, { ListState, Query, QueryResult } from "src/core/stores/data-store";
import FormStore, { FormState } from "src/core/stores/form-store";
import { AccessMode, MemberItem } from "./identities";
import { EntityBasic, EntityType, ISearchEntitiesStore } from "./entities";
import { State } from "@hookstate/core";
import { Role } from "./chat";
import { LogApiKeyItem } from "./bots";

export interface TagItem {
  name: string;
  value: string | string[];
}

export type SkillTypeEnum = 'Dialog' | 'Search' | 'Function';
export type SkillStatus = 'Draft' | 'Published' | 'Retired';
export type SkillTrainingStatus = 'Pending' | 'Training' | 'Trained' | 'Error';
export type SkillContentStepTurnType = "Continue" | "Wait" | "End";
export type SkillContentStepType = "Ask" | "Say" | "CallApi" | "GoTo" | "CallSkill" | "Assign" | "CallLlm" | "Event" | "ExecuteCode" | "Search";
export type SkillContentStepDataType = "String" | "Integer" | "Number" | "Date";
export type SkillContentStepTargetType = "Output" | "Entity";
export type SkillContentStepExecutionSide = "Client" | "Server";

export type ActionType = 'None' | 'MessageBack' | 'OpenUrl';
export type MessageType = 'Plain' | 'Document' | 'FunctionCall' | 'FunctionResult';

export type ParameterType = 'String' | 'Number' | 'Boolean' | 'Date';

export interface SkillSummary {
  id: string;
  title: string;
  description: { [key: string]: string };
  accessMode: AccessMode;
  type: SkillTypeEnum;
  status: SkillStatus;
  trainingStatus: SkillTrainingStatus;
  jobReference: string;
  currentSnapshotDateTime?: Date;
  tags: { [key: string]: any };
}

export interface SkillBasicInfo {
  id: string;
  title: string;
  description: { [key: string]: string };
}

export interface SkillItem {
  id: string;
  title: string;
  description: { [key: string]: string };
  accessMode: AccessMode;
  trainingStatus: SkillTrainingStatus;
  jobReference: string;
  members: MemberItem[];
  type: SkillTypeEnum;
  currentSnapshot: SkillSnapshotItem;
  tags: { [key: string]: any };
  properties: { [key: string]: any };
  logApiKeys: { [key: string]: LogApiKeyItem };
}

export interface SkillSnapshotItem {
  id: string;
  skillId: string;
  content: string;
  jobReference: string;
  hash: string;
  languages: string[];
  bootstrapIntents: string[];
  status: SkillStatus;
  trainingStatus: SkillTrainingStatus;
  variables: { [key: string]: any };
  createdOn: Date;
  createdBy: string;
}

export interface NewSkillForm {
  title: string;
  description: { [key: string]: string };
  accessMode: AccessMode;
  members: MemberItem[];
  type: SkillTypeEnum;
  tags: TagItem[];
  languages: string[];
}

export interface NewSkillItem {
  id?: string;
  title: string;
  description: { [key: string]: string };
  accessMode: AccessMode;
  members: MemberItem[];
  type: SkillTypeEnum;
  tags: TagItem[];
  languages: string[];
}

export interface UpsertSkillSnapshotItem {
  id: string;
  title: string;
  type: SkillTypeEnum;
  description: { [key: string]: string };
  accessMode: AccessMode;
  members: MemberItem[];
  tags: { [key: string]: any };
  content: string;
  languages: string[];
  bootstrapIntents: string[];
  variables: { [key: string]: any };
  logApiKeys: { [key: string]: LogApiKeyItem };
}

export interface SkillSnapshotSummary {
  id: string;
  skillId: string;
  hash: string;
  status: SkillStatus;
  trainingStatus: SkillTrainingStatus;
  createdOn: Date;
  createdBy: string;
  isCurrent: boolean;
}

export interface SkillSnapshotCompareResult {
  skillId: string;
  original: SkillSnapshotCompareItem;
  modified: SkillSnapshotCompareItem;
}

export interface SkillSnapshotCompareItem {
  id: string;
  content: string;
  createdOn: Date;
  createdBy: string;
}

export interface SkillContent {
  entities: SkillContentEntity[];
}

export interface SkillDialogContent extends SkillContent {
  intents: SkillContentIntent[];
  steps: SkillContentStep[];
  parameters: SkillContentParameter[];
  options: DialogSkillOptions
}

export interface DialogSkillOptions {
  behavior: { [key: string]: string };
  requestConfirmation: boolean;

}

export interface SkillSearchContent extends SkillContent {
  collections: string[];
  includePersonalCollections: boolean;
  includeNonPersonalCollections: boolean;
  alpha: number
  confidence: number
  boost: number;
  cutOff: number;
  scoring: string;
  maxResults: number;
  showChatInDocumentViewer: boolean;
  enableInternetSearch: boolean;
  internetSearchService?: string;
  internetSearchMinScore: number;
}

export interface SkillContentIntent {
  intent: string;
  values: { [key: string]: string[] };
}

export interface ContentKeyValue {
  key: string;
  value: string;
}

export interface SkillContentStep {
  step: string;
  condition: string; // string or boolean ??
  action: SkillContentStepAction;
  turn: SkillContentStepTurnType;
}

export interface SkillContentStepAction {
  type: SkillContentStepType;
  text: { [key: string]: string }[];
  questions: { [key: string]: string }[]; // dict array
  suggestions: { [key: string]: string }[];
  dataType: SkillContentStepDataType;
  target: SkillContentStepTargetType;
  targetFormat: 'String' | 'Json' | 'SearchResult';
  entity: string;
  oauthService?: string;
  headers: ContentKeyValue[]; // dict array
  method: string;
  uri: string;
  options: string[];
  minScore: number;
  contentType: string;
  body: string;
  transformer?: string;
  transformerLanguage?: string;
  prompt: { [key: string]: string };
  service: string;
  temperature: number;
  maxTokens: number;
  maxRetry: number;
  //errorMessage: { [key: string]: string }; ??
  stepName: string;
  stepStatus: string;
  skillId: string;
  forwardUserText: boolean;
  executionSide: SkillContentStepExecutionSide;
  //* search fields *//
  collections: string[];
  includePersonalCollections: boolean;
  alpha: number
  confidence: number
  boost: number;
  cutOff: number;
  scoring: string;
  maxResults: number;
  showChatInDocumentViewer: boolean;
  enableInternetSearch: boolean;
  internetSearchService?: string;
  internetSearchMinScore: number;
}

export interface SkillContentParameter {
  name: string;
  description: { [key: string]: string };
  values: { [key: string]: string[] };
  type: ParameterType;
  required: boolean;
}

export interface SkillContentEntity {
  name: string;
  type: EntityType;
  samples: { [key: string]: string[] };
}

export interface SnapshotBasic {
  id: string;
  hash: string;
  createdOn: Date;
  isCurrent: boolean;
}

export interface MessageActionItem {
  title: string,
  description: string,
  score: number,
  type: ActionType,
  sticky: boolean,
  properties: { [key: string]: any },
}

export interface MessageContent {
  title?: string,
  text: string,
  contentType?: string,
  name?: string,
  args?: any,
  output?: any,
  type: MessageType,
  started: string,
  finished?: string,
  error?: string,
}

export interface MessageItem {
  id: number;
  role: Role;
  text: string;
  content: MessageContent;
  score: number;
  properties?: { [key: string]: any };
  action?: MessageActionItem;
  skillId?: string;
  skillSnapshotId?: string;
  skillType?: string;
  actions?: MessageActionItem[];
  timestamp: Date;
  media?: DocumentReferenceItem[];
}

export interface MessageUpdateItem {
  id: string;
  message: MessageItem;
  delta: any;
}

export interface DocumentReferenceItem {
  documentOrSnapshotId: string;
  title?: string;
}

export interface ClaimsIdentityItem {
  name: string,
  preferredLanguage?: string,
  audience?: string[],
}

export interface ResponseItem {
  identity: ClaimsIdentityItem,
  context: any,
  messages: MessageItem[]
}

export const GetSkillDefaultContent = (type: SkillTypeEnum) => {
  return (type === 'Dialog' || type === 'Function') ?
    {
      intents: [],
      entities: [],
      steps: [],
      parameters: [],
      options: {
        behavior: {},
        requestConfirmation: true
      }
    } as SkillDialogContent
    :
    {
      entities: [],
      collections: [],
      includePersonalCollections: false,
      includeNonPersonalCollections: false,
      confidence: 0.1,
      cutOff: 0.86,
      boost: 1,
      alpha: 0.9,
      maxResults: 10,
      scoring: 'linear',
      showChatInDocumentViewer: true,
      enableInternetSearch: false,
      internetSearchService: '',
      internetSearchMinScore: 0.9,
    } as SkillSearchContent;
}

export interface SkillDebugJinjaItem {
  template: string;
  conversationContent: string;
}

export interface ConversationInfo {
  title: string;
  description: string;
  collectionIdOrReference: string;
}

export class SkillSummaryStore extends DataStore<SkillSummary> {
  constructor(baseUrl: string) {
    super(`${baseUrl}/api/skills`, []);
  }
}

export class SkillItemStore extends FormStore<SkillItem, NewSkillItem> {
  constructor(baseUrl: string) {
    super(`${baseUrl}/api/skills`);
  }

  public async saveSnapshot(id: string, item: UpsertSkillSnapshotItem) {
    return await this.handleCallAsync(async () => {
      const result = await this.httpService.put<UpsertSkillSnapshotItem, SkillItem>(`${this.baseUrl}/${encodeURIComponent(id)}`, item);
      this._state.set((s) => {
        s.item = result.data;
        return s;
      });
      return result.data;
    });
  }

  public async publish(id: string) {
    await this.handleCallAsync(async () => {
      await this.httpService.post(`${this.baseUrl}/${encodeURIComponent(id)}/publish`, null);
    });
  }

  public async retire(id: string) {
    await this.handleCallAsync(async () => {
      await this.httpService.post(`${this.baseUrl}/${encodeURIComponent(id)}/retire`, null);
    });
  }

  public async trainSnapshot(skillId: string, snapshotId: string) {
    await this.handleCallAsync(async () => {
      await this.httpService.post<any, any>(`${this.baseUrl}/${encodeURIComponent(skillId)}/snapshots/${snapshotId}/train`, {});
    });
  }

  public async debujJinja(body: SkillDebugJinjaItem) {
    return await this.handleCallAsync(async () => {
      const result = await this.httpService.post<any, any>(`${this.baseUrl}/debugjinja`, body);
      return result.data;
    });
  }
}

export interface IChatStore {
  state: State<FormState<ResponseItem>>;
  ask: (skillId: string, snapshotId: string, conversationId: string, body: any) => Promise<ResponseItem>;
  ask_streamed: (skillId: string, snapshotId: string | undefined, conversationId: string, body: any, signal: AbortSignal) => AsyncGenerator<MessageUpdateItem>;
  saveConversation: (skillId: string, conversationId: string, conversationInfo?: ConversationInfo) => Promise<any>;
  generateConversationInfo: (skillId: string, conversationId: string) => Promise<any>;
}

export class ChatBotStore extends FormStore<ResponseItem, ResponseItem> implements IChatStore {
  constructor(baseUrl: string) {
    super(`${baseUrl}/api/bots`);
  }

  public async ask(skillId: string, snapshotId: string, conversationId: string, body: any) {
    return await this.handleCallAsync(async () => {
      let response = await this.httpService.post<any, ResponseItem>(`${this.baseUrl}/${encodeURIComponent(skillId)}/snapshots/${encodeURIComponent(snapshotId)}/ask?conversationId=${conversationId}`, body);
      return response.data;
    });
  }

  public async *ask_streamed(skillId: string, snapshotId: string | undefined, conversationId: string, body: any, signal: AbortSignal): AsyncGenerator<MessageUpdateItem> {
    const endpoint = snapshotId ?
      `${this.baseUrl}/${encodeURIComponent(skillId)}/snapshots/${encodeURIComponent(snapshotId)}/ask/streamed?conversationId=${conversationId}`
      :
      `${this.baseUrl}/${encodeURIComponent(skillId)}/ask/streamed?conversationId=${conversationId}`;

    this._state.set((s) => {
      s.errorMessage = undefined;
      s.isBusy = true;
      return s;
    });
    try {
      for await (const item of this.httpService.post_streamed<any, MessageUpdateItem>(endpoint, body, signal)) {
        yield item;
      }
    } finally {
      this._state.set((s) => {
        s.isBusy = false;
        return s;
      });
    }
  }

  public async saveConversation(skillId: string, conversationId: string, conversationInfo?: ConversationInfo) {
    return await this.handleCallAsync(async () => {
      let response = await this.httpService.post(`${this.baseUrl}/${encodeURIComponent(skillId)}/conversation/${conversationId}`, conversationInfo);
      return response.data;
    });
  }

  public async generateConversationInfo(skillId: string, conversationId: string) {
    return await this.handleCallAsync(async () => {
      let response = await this.httpService.post<any, ConversationInfo>(`${this.baseUrl}/${encodeURIComponent(skillId)}/conversation/${conversationId}/generateInfo`, null);
      return response.data;
    });
  }
}

export class ChatSkillStore extends FormStore<ResponseItem, ResponseItem> implements IChatStore {
  constructor(baseUrl: string) {
    super(`${baseUrl}/api/skills`);
  }

  public async ask(skillId: string, snapshotId: string, conversationId: string, body: any) {
    return await this.handleCallAsync(async () => {
      let response = await this.httpService.post<any, ResponseItem>(`${this.baseUrl}/${encodeURIComponent(skillId)}/snapshots/${encodeURIComponent(snapshotId)}/ask?conversationId=${conversationId}`, body);
      return response.data;
    });
  }

  public async *ask_streamed(skillId: string, snapshotId: string | undefined, conversationId: string, body: any, signal: AbortSignal): AsyncGenerator<MessageUpdateItem> {
    const endpoint = snapshotId ?
      `${this.baseUrl}/${encodeURIComponent(skillId)}/snapshots/${encodeURIComponent(snapshotId)}/ask/streamed?conversationId=${conversationId}`
      :
      `${this.baseUrl}/${encodeURIComponent(skillId)}/ask/streamed?conversationId=${conversationId}`;

    this._state.set((s) => {
      s.errorMessage = undefined;
      s.isBusy = true;
      return s;
    });
    try {
      for await (const item of this.httpService.post_streamed<any, MessageUpdateItem>(endpoint, body, signal)) {
        yield item;
      }
    } finally {
      this._state.set((s) => {
        s.isBusy = false;
        return s;
      });
    }
  }

  public async saveConversation(skillId: string, conversationId: string, conversationInfo?: ConversationInfo) {
    throw new Error("Not implemented");
  }

  public async generateConversationInfo(skillId: string, conversationId: string) {
    throw new Error("Not implemented");
  }
}

export class SkillSnapshotHistoryStore extends DataStore<SkillSnapshotSummary> {
  private _serviceUri: string;

  constructor(baseUrl: string) {
    super(`${baseUrl}/api/skills`, []);
    this._serviceUri = `${baseUrl}`;
  }

  public setSkillId(skillId: string) {
    this.baseUrl = `${this._serviceUri}/api/skills/${skillId}/history`;
  }

  public async deleteSnapshot(skillId: string, snapshotId: string) {
    await this.handleCallAsync(async () => {
      await this.httpService.delete<any, any>(`${this._serviceUri}/api/skills/${skillId}/snapshots/${snapshotId}`);
    });
  }

  public async trainSnapshot(skillId: string, snapshotId: string) {
    await this.handleCallAsync(async () => {
      await this.httpService.post<any, any>(`${this._serviceUri}/api/skills/${skillId}/snapshots/${snapshotId}/train`, {});
    });
  }

  public async publishSnapshot(skillId: string, snapshotId: string) {
    await this.handleCallAsync(async () => {
      await this.httpService.post<any, any>(`${this._serviceUri}/api/skills/${skillId}/snapshots/${snapshotId}/publish`, {});
    });
  }

  public async retireSnapshot(skillId: string, snapshotId: string) {
    await this.handleCallAsync(async () => {
      await this.httpService.post<any, any>(`${this._serviceUri}/api/skills/${skillId}/snapshots/${snapshotId}/retire`, {});
    });
  }

  public async createNewVersionFromSnapshot(skillId: string, snapshotId: string) {
    await this.handleCallAsync(async () => {
      await this.httpService.post<any, any>(`${this._serviceUri}/api/skills/${skillId}/snapshots/${snapshotId}/createversion`, {});
    });
  }
}

export class SkillSnapshotCompareStore extends FormStore<SkillSnapshotCompareResult, SkillSnapshotCompareResult> {
  constructor(baseUrl: string) {
    super(`${baseUrl}/api/skills`);
  }

  public async compare(skillId: string, snapshotId: string, compareWithPrevious: boolean = false) {
    await this.handleCallAsync(async () => {
      const response = await this.httpService.get<SkillSnapshotCompareResult>(`${this.baseUrl}/${skillId}/snapshots/${snapshotId}/compare?compareWithPrevious=${compareWithPrevious}`);
      this._state.set((s) => {
        s.item = response.data;
        return s;
      });
    });
  }
}

export class SearchSkillEntitiesStore extends DataStore<EntityBasic> implements ISearchEntitiesStore {
  constructor(baseUrl: string) {
    super(`${baseUrl}/api/skills`, []);
  }

  public async get(id: string, name: string) {
    await this.handleCallAsync(async () => {
      const response = await this.httpService.get<QueryResult<EntityBasic>>(`${this.baseUrl}/${encodeURIComponent(id)}/entities?name=${name}`);
      this._state.set((s) => {
        s.items = response.data.items || (response.data as any).value || [];
        s.count = response.data.count || (response.data as any)['@odata.count'] || 0;
        return s;
      });
    });
  }
}

export interface ISnapshotSelectStore {
  state: State<ListState<SnapshotBasic>>
  get: (query: Partial<Query>) => Promise<any>
}

export class SkillSnapshotSelectStore extends DataStore<SnapshotBasic> implements ISnapshotSelectStore {
  private _serviceUri: string;

  constructor(baseUrl: string) {
    super(`${baseUrl}/api/skills`, []);
    this._serviceUri = `${baseUrl}`;
  }

  public setSkillId(skillId: string) {
    this.baseUrl = `${this._serviceUri}/api/skills/${skillId}/history`;
  }

  public async get(query: Partial<Query>) {
    await this.handleCallAsync(async () => {
      const response = await this.httpService.get<QueryResult<SnapshotBasic>>(`${this.baseUrl}?${DataStore.buildUrl(query as Query)}`);
      this._state.set((s) => {
        s.items = response.data.items || (response.data as any).value || [];
        s.count = response.data.count || (response.data as any)['@odata.count'] || 0;
        return s;
      });
    });
  }
}